import { UniRoColumn } from "./Bit/UniRoColumn";
import { CrmConfigSchema } from "./ConfigSchema/CrmConfigSchema";
import { PrimerConfigSchema } from "./ConfigSchema/PrimerConfigSchema";
import Dashboard from "./Dashboard/Dashboard";

export const PluginsMap: any = {

    'bit-UniRoColumn': UniRoColumn,


    "custom.routes": {
        '/c/dashboard/main': Dashboard,
    },

    config: {
        primer: PrimerConfigSchema,
        crm: CrmConfigSchema,
    }
}